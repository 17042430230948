import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { BigHero } from "./components/BigHero"

export default () => {
  const { strapiLandingPage } = useStaticQuery(graphql`
    {
      strapiLandingPage {
        bigHero {
          description
          button {
            theme
            targetUrl
            value
          }
        }
      }
    }
  `)

  return (
    <BigHero
      button={strapiLandingPage.bigHero.button}
      description={strapiLandingPage.bigHero.description}
    />
  )
}
