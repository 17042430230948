import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import getGatsbyImage from "utils/getGatsbyImage"
import { GatsbyImage } from "gatsby-plugin-image"
import StrapiButton, { StrapiButtonProps } from "components/Strapi/StrapiButton"
import * as s from "./index.styles"

export interface IProps {
  description?: string
  button?: StrapiButtonProps
  style?: {
    fontSize?: string
  }
}

export const BigHero = (props: IProps) => {
  const { backgroundImage, logoImage, florenceImage, samImage } =
    useStaticQuery(graphql`
      {
        backgroundImage: file(relativePath: { eq: "woods-banner.jpg" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 1920)
          }
        }
        florenceImage: file(relativePath: { eq: "florence.png" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 420)
          }
        }
        samImage: file(relativePath: { eq: "sam.png" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 420)
          }
        }
        logoImage: file(relativePath: { eq: "will-logo.png" }) {
          publicURL
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 520)
          }
        }
      }
    `)

  return (
    <s.Wrapper>
      <GatsbyImage
        image={getGatsbyImage(backgroundImage.childImageSharp.gatsbyImageData)}
        alt="background"
        className="background-image"
      />
      <s.FlorenceImage>
        <GatsbyImage
          image={getGatsbyImage(florenceImage.childImageSharp.gatsbyImageData)}
          alt="Florence"
          className="florence-image"
        />
      </s.FlorenceImage>
      <s.ContentContainer>
        <s.WillImage>
          <GatsbyImage
            image={getGatsbyImage(logoImage.childImageSharp.gatsbyImageData)}
            alt="Logo"
            className="will-image"
          />
        </s.WillImage>
        <s.Description $fontSize={props.style?.fontSize || "25px"}>
          {props.description}
        </s.Description>
        <s.Button>
          {props.button && <StrapiButton size="regular" {...props.button} />}
        </s.Button>
      </s.ContentContainer>
      <s.SamImage>
        <GatsbyImage
          image={getGatsbyImage(samImage.childImageSharp.gatsbyImageData)}
          alt="Sam"
          className="sam-image"
        />
      </s.SamImage>
    </s.Wrapper>
  )
}
