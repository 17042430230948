import styled from "styled-components"
import { ComponentWrapperProps } from "."

function getJustifyContent(props: ComponentWrapperProps) {
  if (props.image) {
    return props.$imageFirst === true ? "flex-start" : "flex-end"
  }
  return "center"
}

export const Wrapper = styled.div<ComponentWrapperProps>`
  position: relative;
  min-height: 450px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  box-shadow: inset 0 -20px 40px 0px rgb(50, 23, 118),
    inset 0 20px 40px 0px rgb(50, 23, 118);

  .background {
    transform: ${props => (props.$imageFirst ? "scaleX(-1)" : "none")};
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`

export const Container = styled.div<ComponentWrapperProps>`
  display: flex;
  flex-direction: row;
  flex-direction: ${props =>
    props.$imageFirst === true ? "row" : "row-reverse"};
  max-width: 960px;
  align-items: center;
  justify-content: ${props => getJustifyContent(props)};
  width: 100%;
  height: 100%;
  gap: 50px;
  margin: 0 auto;
  padding: 10px;

  @media (max-width: 1120px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    margin: 30px 5px;
  }
`

export const Image = styled.div<ComponentWrapperProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  flex: 1;

  @media (max-width: 1120px) {
    ${props =>
      props.$imageFirst === false &&
      `
      padding: 0 10px;
    `}
    justify-content: center;
  }

  > img {
    width: calc(100% - 30px);
    height: 100%;
    object-fit: cover;
  }
`

export const ContentContainer = styled.div<ComponentWrapperProps>`
  text-align: center;
  font-family: "Rammetto One", cursive;
  color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`

export const Title = styled.h2<{
  $size?: string
  $color?: string
}>`
  letter-spacing: 0.73px;
  line-height: 1.4em;
  text-shadow: 0 0.14em 0 #afafaf, 0 0.28em 0 rgba(0, 0, 0, 0.4);
  font-size: ${props => props.$size};
  font-weight: normal;
  color: ${props => props.$color};
  margin: 0;

  @media (max-width: 480px) {
    padding: 0 10px;
  }
`
export const Description = styled.p<{
  $fontSize?: string
}>`
  font-size: ${props => props.$fontSize};
  font-family: "Libre Baskerville";
  text-wrap: wrap;
  padding: 0 30px;
`
