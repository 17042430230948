import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import HomePageBigHero from "components/Strapi/HomePageBigHero"
import HomePageHero from "components/Strapi/HomePageHero"
import BlogHero from "components/Strapi/BlogHero"
import PartnersComponent from "components/Strapi/PartnersComponent"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      defaultBackground: file(relativePath: { eq: "purple-banner.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, width: 1980)
        }
      }
      strapiLandingPage {
        heroes {
          id
          imageFirst
          image {
            url
            localFile {
              childImageSharp {
                fluid(maxWidth: 480) {
                  src
                }
                gatsbyImageData
              }
            }
          }
          description
          title
          button {
            theme
            value
            targetUrl
          }
        }
      }
    }
  `)

  return (
    <>
      <HomePageBigHero />
      {(data?.strapiLandingPage?.heroes || []).map((hero, id) => (
        <HomePageHero
          key={id}
          {...hero}
          defaultBackground={data.defaultBackground}
        />
      ))}
      <BlogHero />
      <PartnersComponent />
    </>
  )
}

export default IndexPage
