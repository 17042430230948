import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'nzk-react-components'
import RequestEmailButton from 'components/Buttons/RequestEmailButton'
import LocalButton from '../../UI/Button'
import scrollToPlansSection from '../actions/scrollToPlansSection'
import scrollToTeacherCTA from '../actions/scrollToTeacherCTA'

const ACTIONS = {
  'SCROLL_TO_PLANS': scrollToPlansSection,
  'SCROLL_TO_TEACHER_CTA': scrollToTeacherCTA,
}

export interface StrapiButtonProps extends Strapi.StrapiButton {
  size?: "small" | "x-small" | "regular" | "large" | "x-large" | undefined
  theme: "confirm" | "primary" | "the-pink" | "purple"
}

const StrapiButton = (props: StrapiButtonProps) => {
  const onClick = props.action && ACTIONS[props.action] ? () => {
    ACTIONS[props.action!]()
  } : undefined


  const button = typeof window !== 'undefined' ? <Button size={props.size} theme={props.theme} onClick={onClick}>
    {props.value}
  </Button>
    // @ts-ignore
    : <LocalButton size={props.size} theme={props.theme} onClick={onClick}>{props.value}</LocalButton>

  if (props.requestDetails) {
    return <RequestEmailButton submitButton={
      <Button size='small' theme='primary'>Submit</Button>
    } reason={props.requestDetailsReason || ''} onSubmit={() => {
      if (props.targetUrl) {
        window.open(props.targetUrl)
      }
    }}>{props.value}</RequestEmailButton>
  }

  if (props.targetUrl) {
    return <Link to={props.targetUrl}>{button}</Link>
  }
  return button
}

StrapiButton.defaultProps = {
  size: 'regular'
}

export default StrapiButton
